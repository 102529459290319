@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.hero {
  background-color: inherit;
  @include cover-background;

  &__content {
    @include flex-h-center;
    min-height: 100vh;
    position: relative;
    flex-direction: column;
    padding-top: 105px;
    z-index: 1;

    @include media(tablet-wide) {
      padding-top: 0;
      min-height: 700px;
    }

    h1 {
      max-width: 560px;
      line-height: 1.2;
      margin-bottom: 24px;
    }

    span {
      color: var(--color-blue-700);
    }

    p {
      max-width: 590px;
      margin-bottom: 32px;
    }
  }
}