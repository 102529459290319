@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.team {
  h2 {
    max-width: 800px;
    width: 100%;
    text-align: center;
    line-height: 1.2;
    margin: 0 auto 25px;

    span {
      color: var(--color-blue-700);
    }

    +p {
      text-align: center;
      margin-bottom: 65px;
    }
  }

  &__list {
    display: flex;
    gap: 24px;

    @include media(tablet) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}