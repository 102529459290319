@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.reviews {
  text-align: center;

  h2 {
    line-height: 1.25;
    margin: 0 auto 24px;

    +p {
      max-width: 1010px;
      margin: 0 auto 65px;
    }
  }

  &__list {
    @include flex-sb;
    gap: 24px;

    @include media(tablet) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  &__item {
    position: relative;
    max-width: 390px;
    width: 100%;
    background-color: var(--color-blue-100);
    border-radius: var(--radius-main);
    padding: 52px 32px 32px;

    &::after,
    &::before {
      @include pseudo;
      height: 42px;
      left: 50%;
      top: 32px;
      transform: translateX(-50%);
    }

    &::after {
      @include contain-background;
      background-image: var(--quotes);
      width: 42px;
    }

    &::before {
      @include pseudo;
      background-color: var(--color-blue-100);
      width: 74px;
    }

    p {
      font-size: 16px;
      margin-bottom: 16px;
    }
  }

  &__content {
    @include flex-col;
    height: 100%;
    border-top: 1px solid var(--color-blue-300);
    padding-top: 38px;
  }

  h3 {
    font-size: 16px;
    margin: 0 auto;
  }
}