@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.info {
  &__wrapper {
    @include flex-all-sb;

    @include media(tablet) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__content {
    @include flex-col;
    gap: 32px;
    max-width: 495px;

    @include media(tablet) {
      max-width: 100%;
    }
  }

  h2 {
    line-height: 1.2;
  }

  img {
    max-width: 595px;
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: var(--radius-main);
    margin-right: 30px;

    @include media(tablet-wide) {
      max-width: 495px;
    }

    @include media(tablet) {
      max-width: 100%;
      aspect-ratio: 16/7;
      margin-right: 0;
      margin-bottom: 30px;
    }
  }

  &__slogan {
    padding: 24px;
    text-align: center;
    background-color: var(--color-blue-300);
    color: var(--color-blue-900);
    border-radius: calc(var(--radius-main) - 8px);

    @include media(tablet) {
      padding: 15px;
    }
  }

  &.section {
    padding-block: 64px 86px;
  }
}