@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.about {
  &__wrapper {
    @include flex-all-sb;

    @include media(tablet) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__content {
    max-width: 495px;
    margin-right: 30px;

    @include media(tablet-wide) {
      max-width: 430px;
    }

    @include media(tablet) {
      max-width: 100%;
      margin-bottom: 30px;
    }
  }

  &.section {
    padding-block: 86px 64px;
  }

  h2 {
    margin-bottom: 35px;
    line-height: 1.1;
  }
}