@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.values {
  background-color: var(--color-blue-100);

  h2 {
    margin-bottom: 10px;

    +p {
      max-width: 270px;
      line-height: 1.6;
    }
  }
}