@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.member {
  max-width: 390px;
  @include flex-col;
  align-items: center;
  background-color: var(--color-white);
  border-radius: var(--radius-main);
  box-shadow: 0px 5px 15px 10px var(--color-blue-100);
  text-align: center;
  padding: 30px;

  img {
    border-radius: calc(var(--radius-main) * 4);
    margin-bottom: 24px;
  }

  h3 {
    font-size: 24px;
    margin-bottom: 5px;
  }

  span {
    color: var(--color-blue-900);
    font-weight: var(--fw-600);
    font-size: 16px;
    margin-bottom: 15px;
  }

  p {
    font-size: 14px;
  }
}