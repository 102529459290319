@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.case {
  @include flex-all-sb;

  @include media(mobile-l) {
    flex-direction: column;
    align-items: flex-start;
  }

  h2 {
    line-height: 1.2;
  }

  &__content {
    @include flex-col;
    gap: 32px;
    max-width: 495px;
    width: 100%;
  }

  img {
    max-width: 595px;
    width: 100%;
    aspect-ratio: 16/13;
    object-fit: cover;
    border-radius: var(--radius-main);

    @include media(tablet-wide) {
      max-width: 495px;
    }

    @include media(tablet) {
      max-width: 450px;
    }

    @include media(mobile-l) {
      max-width: 100%;
      aspect-ratio: 16/10;
      margin-bottom: 30px;
    }
  }

  &--reverse {
    @include media(mobile-l) {
      flex-direction: column-reverse;
    }

    img {
      order: 2;
    }

    .case__content {
      margin-right: 35px;
    }
  }

  &:not(.case--reverse) {
    img {
      margin-right: 35px;
    }
  }

  &--promo {
    img {
      max-width: 390px;
      height: 390px;

      @include media(mobile-l) {
        max-width: 100%;
      }
    }

    .case__content {
      max-width: 700px;
    }
  }

}