@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.categories {
  &.section {
    padding-block: 86px 60px;
  }

  h2 {
    line-height: 1.2;
    margin: 0 auto 65px;
  }
}