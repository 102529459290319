@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10;
  margin: 0 auto;
  font-weight: var(--fw-400);
  width: 100%;
  background-color: var(--color-white);
  padding-block: 20px;
  transition-property: transform, padding;
  transition-duration: 0.15s;

  @include media(tablet-wide) {
    padding-block: 15px;
  }

  .logo {

    &--tablet {
      display: none;

      @include media(tablet) {
        display: block;
        margin-right: auto;
      }
    }
  }

  &.hide {
    transform: translateY(calc(-100% - 30px));
  }

  &.scroll {
    background-color: var(--color-white);
    border-bottom: 1px solid var(--color-blue-300);
    padding-block: 10px;
  }

  &__btns {
    @include media(tablet) {
      margin: 0 auto;
      gap: 30px;
    }
  }
}