@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.features {
  &__wrapper {
    h2 {
      line-height: 1.2;
      margin: 0 auto 65px;
    }
  }
}