@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.serv-card {
  position: relative;
  @include flex-col;
  height: 100%;
  width: 100%;
  padding-top: 70px;

  &::before {
    @include pseudo;
    @include contain-background;
    top: 0;
    left: 0;
    width: 48px;
    filter: var(--filter-blue-900);
    height: 48px;
  }

  &--comprehensive {
    &::before {
      background-image: var(--comprehensive);
    }
  }

  &--collision {
    &::before {
      background-image: var(--collision);
    }
  }

  &--liability {
    &::before {
      background-image: var(--liability);
    }
  }

  &--additional {
    &::before {
      background-image: var(--additional);
    }
  }

  &--integrity {
    &::before {
      background-image: var(--integrity);
    }
  }

  &--quality {
    &::before {
      background-image: var(--quality);
    }
  }

  &--reliability {
    &::before {
      background-image: var(--reliability);
    }
  }

  &--innovation {
    &::before {
      background-image: var(--innovation);
    }
  }

  &--satisfaction {
    &::before {
      background-image: var(--satisfaction);
    }
  }

  h3 {
    margin-bottom: 20px;
  }

  p {
    font-size: 16px;
  }

  &--about,
  &--services {
    background-color: var(--color-white);
    box-shadow: 0px 5px 10px 5px var(--color-blue-100);
    border-radius: var(--radius-main);

    &::before {
      left: 30px;
    }
  }

  &--about {
    padding: 128px 30px 60px;
    max-width: 286px;

    &::before {
      top: 60px;
    }

    @include media(mobile-m) {
      max-width: 100%;
      padding: 128px 25px 25px;
    }
  }

  &--services {
    padding: 100px 30px 55px;

    &::before {
      top: 30px;
    }
  }
}