@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.grid {
  $root: &;
  display: grid;

  &__item {
    position: relative;
    justify-content: space-between;
  }

  &[data-items="1"] {
    --column: 3;
    gap: 58px 24px;
    justify-content: space-between;
    grid-template-columns: repeat(var(--column), 1fr);
    margin-bottom: 50px;

    @include media(tablet) {
      --column: 2;
    }

    @include media(mobile-m) {
      --column: 1;
    }

    .grid__item {
      &:nth-child(1) {
        grid-row: 1/3;

        @include media(tablet) {
          grid-row: 1/2;
          grid-column: 1/3;
        }

        @include media(mobile-m) {
          grid-row: auto;
          grid-column: auto;
        }
      }
    }
  }

  &[data-items="2"] {
    --column: 2;
    gap: 24px;
    justify-items: center;
    grid-template-columns: repeat(var(--column), 1fr);

    @include media(tablet) {
      --column: 3;
      margin: 0 auto;
    }

    @include media(mobile-l) {
      --column: 2;
    }

    @include media(mobile-m) {
      --column: 1;
      margin: 0;
      justify-items: start;
    }

    .grid__item {
      &:nth-child(3) {
        grid-column: 1/3;

        @include media(tablet) {
          grid-column: auto;
        }

        @include media(mobile-l) {
          grid-column: 1/3;
        }

        @include media(mobile-m) {
          grid-column: 1/2;
        }
      }
    }
  }

  &[data-items="3"] {
    --column: 3;
    gap: 45px 24px;
    justify-content: space-between;
    grid-template-columns: repeat(var(--column), 1fr);

    @include media(tablet) {
      --column: 2;
    }

    @include media(mobile-m) {
      --column: 1;
    }
  }

  &[data-items="4"] {
    --column: 2;
    gap: 24px;
    grid-template-columns: repeat(var(--column), 1fr);

    @include media(mobile-m) {
      --column: 1;
    }
  }

  &[data-items="5"] {
    --column: 3;
    gap: 24px;
    grid-template-columns: repeat(var(--column), 1fr);

    @include media(tablet) {
      --column: 2;
    }

    @include media(tablet) {
      --column: 2;
    }

    @include media(mobile-m) {
      --column: 1;
    }

    .grid__item {
      position: relative;
      display: flex;
      flex-direction: column;
      background-color: var(--color-white);
      box-shadow: 0px 5px 10px 5px var(--color-blue-100);
      border-radius: var(--radius-main);
      padding: 100px 30px 30px;

      @include media(tablet) {
        &:nth-child(3) {
          grid-column: 1/3;

          @include media(mobile-m) {
            grid-column: auto;
          }
        }
      }

      &::before {
        @include pseudo;
        @include contain-background;
        top: 0;
        left: 0;
        width: 48px;
        height: 48px;
        top: 30px;
        left: 30px;
        background-image: var(--check);
      }

      h3 {
        margin-bottom: 24px;
      }

      p {
        font-size: 16px;
      }
    }

  }
}