@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.faq {
  padding-bottom: 86px;

  h1 {
    line-height: 1.2;
    margin: 0 auto 50px;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  details {
    position: relative;
    background-color: var(--color-blue-100);
    border-radius: calc(var(--radius-main) - 8px);
    cursor: pointer;
  }

  details[open] {
    summary {
      padding-bottom: 10px;
    }
  }

  summary {
    color: var(--color-blue-900);
    font-size: 18px;
    line-height: 1.2;
    font-weight: var(--fw-600);
    padding: 15px 80px 15px 25px;

    +p {
      padding: 0 80px 15px 25px;
      line-height: 1.65;
      font-family: var(--font-family-primary);
      font-size: 16px;

      @include media(mobile-l) {
        padding: 0 75px 15px 25px;
      }
    }
  }

  summary::before {
    content: "";
    position: absolute;
    display: inline-block;
    background-image: var(--chevron);
    filter: var(--filter-blue-900);
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
    width: 32px;
    height: 32px;
    right: 24px;

    @include media(mobile-l) {
      right: 15px;
    }
  }

  details[open]>summary::before {
    transform: translateY(-50%) rotate(0);
  }

}