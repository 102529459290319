@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.contact {
  min-height: 900px;
  @include cover-background;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  @include media(tablet) {
    min-height: auto;
  }

  h2 {
    line-height: 1.5;
    margin-bottom: 30px;

    @include media(tablet) {
      margin: 0 auto 30px;
    }
  }

  .container--wide {
    padding: 0;
  }

  &__wrapper {
    max-width: 915px;
    position: relative;
    background-color: var(--color-white);
    margin-left: auto;
    padding-left: 105px;

    @include media(tablet) {
      max-width: 100%;
      padding-inline: 15px;
      background-color: rgba(255, 255, 255, 0.9);
    }
  }

  &__inner {
    display: flex;

    @include media(tablet) {
      justify-content: center;
    }

    @include media(mobile-m) {
      flex-direction: column-reverse;
      gap: 25px;
    }
  }

  &__form {
    max-width: 390px;
    width: 100%;
    margin-right: 24px;

    @include media(mobile-m) {
      max-width: 100%;
    }
  }

  &__content {
    @include flex-col;
    gap: 30px;

    @include media(mobile-m) {
      gap: 15px;
    }
  }

  label {
    display: inline-block;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 17px;
    }
  }

  input {
    width: 100%;
    border-radius: calc(var(--radius-main) * 4 + 2px);
  }

  input,
  textarea {
    width: 100%;
    background-color: var(--color-blue-100);
    font-size: 16px;
    padding: 15px;
    line-height: 1;
    font-family: var(--font-family-primary);
    border-radius: calc(var(--radius-main) - 4px);
  }

  textarea {
    border: none;
    min-height: 155px;
    resize: vertical;
  }

  h3 {
    line-height: 1;
  }

  abbr {
    text-decoration: none;
    text-transform: uppercase;
  }

  a {
    color: var(--color-blue-900);
    line-height: 1.4;
  }

  &__list,
  a {
    font-size: 16px;
    font-family: var(--font-family-contact);
  }

  &__list {
    line-height: 1.4;
  }

  .btn {
    max-width: 100%;
    width: 100%;
  }
}