@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.services {
  background-color: var(--color-blue-100);

  h2 {
    max-width: 286px;
    line-height: 1.2;
    margin-bottom: 26px;

    +p {
      max-width: 286px;

      @include media(tablet) {
        max-width: 100%;
      }
    }
  }

  .btn {
    margin: 0 auto;
  }
}