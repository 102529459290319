@mixin flex {
  display: flex;
}

@mixin flex-v-center {
  display: flex;
  align-items: center;
}

@mixin flex-h-center {
  display: flex;
  justify-content: center;
}

@mixin flex-all-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-all-sb {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin flex-sb {
  display: flex;
  justify-content: space-between;
}

@mixin flex-col {
  display: flex;
  flex-direction: column;
}