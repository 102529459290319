@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

.policy {
  padding-bottom: 86px;

  h1 {
    margin: 0 auto 45px;
    line-height: 1.2;
  }

  p {
    font-size: 18px;
    line-height: 1.65;

    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }

  >* {
    font-family: var(--font-family-primary);
  }

  &__content {
    &:not(:last-child) {
      margin-bottom: 65px;
    }
  }

  h2 {
    font-size: 32px;
    color: var(--color-blue-900);
    font-weight: var(--fw-600);
    margin-bottom: rem(20);
  }

  ul {
    color: var(--color-black-800);
    margin-bottom: 24px;
    padding-left: 15px;
  }

  li {
    padding-left: 5px;
    font-size: 20px;

    &:not(:last-child) {
      margin-bottom: 3px;
    }

    &::marker {
      content: '\2713';
      margin-right: 5px;
    }
  }

  a {
    text-decoration: underline;
  }

  b,
  strong {
    font-weight: var(--fw-600);
  }

  @include media(tablet-wide) {
    padding: 70px 0;

    h1 {
      font-size: 42px;
    }

    h2 {
      font-size: 38px;
    }
  }

  @include media(mobile-l) {
    padding: 50px 0 40px;

    h1 {
      font-size: 35px;
    }

    h2 {
      font-size: 28px;
    }
  }
}