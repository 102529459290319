@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.cases {
  &__item {
    &:not(:last-child) {
      border-bottom: 1px solid var(--color-blue-300);
    }

    &.section {
      padding-block: 64px;
    }

    &:first-child {
      &.section {
        padding-block: 86px 64px;
      }
    }

    &:last-child {
      &.section {
        padding-block: 64px 86px;
      }
    }
  }
}